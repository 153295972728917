.icon-plus:before {
  content: "\f067";
}
.checkbox {
  padding-left: 0;
  line-height: 16px;
  vertical-align: middle;
}
.checkbox div.radio {
  display: inline-block;
}
.checkbox div.radio span {
  float: left;
  top: -4px;
}
.checkbox label {
  color: #212121;
}
.radio {
  vertical-align: middle;
}
select {
  text-transform: none;
}
input[type="button"] {
  appearance: button;
  cursor: pointer;
}
input[type="reset"] {
  appearance: button;
  cursor: pointer;
}
input[type="submit"] {
  appearance: button;
  cursor: pointer;
}
input[readonly] {
  cursor: not-allowed !important;
  background-color: #9B9B9B !important;
} 
input[disabled] {
  cursor: not-allowed !important;
  background-color: #9B9B9B !important;
}
input[type="radio"] {
  box-sizing: border-box;
  padding: 0px 0px 0px 0px;
}
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0px 0px 0px 0px;
}
input[type="search"]::search-cancel-button {
  appearance: none;
}
input[type="search"]::search-decoration {
  appearance: none;
}
input::focus {
  border: 0;
  padding: 0px 0px 0px 0px;
}
legend {
  color: #212121;
}
form .add_phone_number {
  margin: -15px 0px 5px 0px;
}
form .remove_phone_number {
  margin: -15px 0px 5px 0px;
}
fieldset {
  border: 0;
  padding: 0px 0px 0px 0px;
}
legend {
  margin-bottom: 18px;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  padding: 0px 0px 0px 0px;
  font-size: 19.5px;
  line-height: inherit;
}
fieldset {
  margin: 0px 0px 0px 0px;
}
input[type="search"] {
  box-sizing: border-box;
}
input[type="radio"] {
  margin: 2px 5px 0px 0px;
  line-height: normal;
}
input[type="radio"]:focus {
  outline: none;
}
input[type="checkbox"] {
  margin: 2px 5px 0px 0px;
  line-height: normal;
}
input[type="checkbox"]:focus {
  outline: none;
}
input[type="file"]:focus {
  outline: none;
}
input[type="number"]::inner-spin-button {
  height: auto;
}
input[type="number"]::outer-spin-button {
  height: auto;
}
.page-overheading {
  position: relative !important;
  top: -23px !important;
  left: -9px !important;
  z-index: 1 !important;
  display: inline-block;
  overflow: hidden;
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px 5px 0px 5px;
  max-width: 100%;
  height: 20px !important;
  line-height: 16px !important;
  font-size: 16px !important;
  font-weight: 400;
  white-space: nowrap;
  color: #212121;
  background-color: #FFFFFF;
  text-transform: none;
}
.footer_links {
  border: none;
  padding-top: 0;
}
form.box {
  padding-bottom: 0;
  line-height: 20px;
  min-height: 270px;
}
form .page-subheading {
  border-bottom: 0 solid #d6d4d4;
  margin: 10px 0px 10px 0px;
  padding: 0px 0px 0px 0px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  text-transform: none;
  color: #212121;
  height: 20px !important;
  line-height: 16px !important;
  font-size: 16px !important;
}
form .inline-infos {
  display: inline-block;
  float: left;
  margin: 10px 0px 5px 0px;
  font-weight: 600;
  clear: both;
}
form .inline-infos + .inline-infos {
  margin: 5px 0px 10px 0px;
}
form .inline-errors {
  display: inline-block;
  float: left;
  margin: 10px 0px 5px 0px;
  font-weight: 600;
  color: #D02A2C;
  clear: both;
}
form .inline-errors + .inline-errors {
  margin: 5px 0px 10px 0px;
}
form .ajax_blad {
  display: table; 
  float: none; 
  color: #F13340;
  font-weight: 400;
  clear: left;
}
form .form-group {
  display: block;
  overflow: visible;
  float: none;
  margin: 0px 0px 0px 0px;
  clear: left;
  margin-bottom: 4px;
}
form .form-group:not(.checkbox):not(.radio).not(.textarea) {
  height: 54px;
}
form .form-group.textarea {
  min-height: 54px;
}
form .form-group.plus_height1:not(.checkbox):not(.radio).not(.textarea) {
  height: 73px;
}
form .form-group.textarea.plus_height1 {
  min-height: 73px;
}
form .form-group:not(.checkbox):not(.radio).not(.textarea).plus_height2 {
  height: 94px;
}
form .form-group.textarea.plus_height2 {
  min-height: 94px;
}
@media (min-width: 768px) {
  form .form_group_2_left {
    margin: 0px 8px 0px 0px !important;
    width: calc(50% - 8px)!important; 
    float: left!important;
    clear: left;
  }
  form .form_group_2_right {
    float: left!important;
    margin: 0px 0px 0px 8px!important;
    width: calc(50% - 8px)!important;
    clear: none;
  }
  form .form_group_3_left {
    float: left!important;
    margin: 0px 7.5px 0px 0px!important;
    width: calc((100% - 30px) / 3)!important; 
    clear: left;
  }
  form .form_group_3_center {
    float: left!important;
    margin: 0px 7.5px 0px 7.5px!important;
    width: calc((100% - 30px) / 3)!important; 
    clear: none;
  }
  form .form_group_3_right {
    float: left!important;
    margin: 0px 0px 0px 7.5px!important;
    width: calc((100% - 30px) / 3)!important; 
    clear: none;
  }
}
form .form-group.invoice_for_company {
  min-height: 120px!important;
}
form .form-group.invoice_for_company label {
  display: block;
  float: none;
  margin: 10px 0px 10px 0px;
  min-height: 60px!important;
  cursor: pointer;
}
form .form-group.invoice_for_company label p {
  display: block;
  float: left;
  outline: none;
  border: 1px solid #A9A9A9;
  border-radius: 50%;
  margin: 0px 10px 0px 0px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  box-sizing: border-box;
}
form .form-group.invoice_for_company label p:hover {
  border: 2px solid #A9A9A9!important;
}
form .form-group.invoice_for_company label p.active {
  display: block;
  float: left;
  border: none!important;
  margin: 0px 10px 0px 0px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  background-image: url('/img/radio.svg');
  background-repeat: round;
  background-position: top left;
}
form .form-group.invoice_for_company label span {
  display: contents;
  float: left;
  padding: 2px 0px 0px 0px;
  height: 20px;
  font-size: 14px;
  line-height: 14px;
}
form .form-group.invoice_for_company label output {
  display: block;
  float: none;
  margin: 0px 0px 0px 30px;
  font-size: 12px;
  line-height: 12px;
  clear: both;
}
form .form-group.checkbox {
  display: inline-block;
  float: left;
  margin: 5px 0px 5px 0px;
  padding: 0px 0px 0px 0px;
  min-height: 15px;
}
form .form-group.radio {
  display: inline-block;
  float: left;
  margin: 5px 0px 5px 0px;
  padding: 0px 0px 0px 0px;
  min-height: 15px;
}
form .form-group.checkbox label {
  display: inline;
  float: left;
  margin: 0px 0px 0px 0px;
  font-weight: 400;
  cursor: pointer;
}
form .form-group.checkbox input[type="checkbox"] {
  float: left;
}
form .form-group.radio input[type="radio"] {
  float: left;
}
form .form-group .iti__flag-container {
  font-weight: 400;
  color: #212121;
}
form .form-group .form-control {
  border: 1px solid #D6D4D4;
  border-radius: 0;
  margin: 0px 0px 0px 1px;
  padding: 8px 30px 8px 8px;
  width: calc(100% - 2px);
  max-width: 900px;
  height: 40px;
  font-size: 14px;
  line-height: 21px;
  vertical-align: middle;
  color: #212121;
  background-color: #FFFFFF;
  box-shadow: none;
}
form .form-group textarea.form-control {
  resize: auto; 
  min-width: calc(100% - 2px); 
  min-height: 40px;
}
form .form-group select[multiple].form-control {
  height: auto;
}
form .form-group select[size].form-control {
  height: auto;
}
form .form-group .form-control:placeholder {
  color: #C3C3C3;
}
form .form-group .form-control::input-placeholder {
  color: #C3C3C3;
}
form .form-group .form-control:not([readonly]):not([readonly]):focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: none;
} 
form .form-group .form-control[readonly] {
  opacity: 1;
  color: #212121 !important;
  cursor: not-allowed !important;
  background-color: #9B9B9B !important;
}
form .form-group .form-control[disabled] {
  opacity: 1;
  color: #212121 !important;
  cursor: not-allowed !important;
  background-color: #9B9B9B !important;
}
form fieldset[readonly] .form-control {
  opacity: 1;
  color: #212121 !important;
  cursor: not-allowed !important;
  background-color: #9B9B9B !important;
}
form fieldset[disabled] .form-control {
  opacity: 1;
  color: #212121 !important;
  cursor: not-allowed !important;
  background-color: #9B9B9B !important;
}
@media (min-width: 1200px) {
  form .form-group .form-control {
    float: left;
  }
}
form .form-group.radio label {
  display: inline;
  margin: 0px 0px 0px 0px;
  font-weight: 400;
  cursor: pointer;
}
@media (min-width: 1200px) {
  form .form-group .form-control label {
    float: left;
    clear: both;
    width: 100%;
  }
}
form .form-group input[type="file"] {
  display: block;
} 
form .form-group legend {
  display: block;
}
form .form-group.checkbox[readonly],
form .form-group.checkbox[disabled],
form .form-group.radio[readonly],
form .form-group.radio[disabled],
form fieldset[readonly] .form-group.checkbox,
form fieldset[disabled] .form-group.checkbox,
form fieldset[readonly] .form-group.radio,
form fieldset[disabled] .form-group.radio,
form fieldset[readonly] input[type="radio"],
form fieldset[disabled] input[type="radio"],
form fieldset[readonly] input[type="checkbox"],
form fieldset[disabled] input[type="checkbox"],
form input[type="radio"][readonly],
form input[type="radio"][disabled],
form input[type="checkbox"][readonly], 
form input[type="checkbox"][disabled] {
  cursor: not-allowed;
}
form .form-group:not(.checkbox):not(.radio) label {
  position: relative;
  top: 0px;
  left: 7px;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  margin: 0px 0px 0px 0px;
  padding: 0px 5px 0px 5px;
  max-width: 100%;
  height: 16px;
  white-space: nowrap;
  line-height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #212121;
  background-color: #FFFFFF;
}
form .form-group:not(.checkbox):not(.radio) .iti_prefix {
  z-index: 3;
}
form .form-group:not(.checkbox):not(.radio) .iti_prefix + label {
  margin: 0px 0px 0px 26px !important;
}
form .form-group:not(.checkbox):not(.radio) label sup {
  display: inline-block;
  margin: 0px 0px 0px 1px;
  color: #212121;
}
form .form-group:not(.checkbox):not(.radio) > input, 
form .form-group:not(.checkbox):not(.radio) > .iti, 
form .form-group:not(.checkbox):not(.radio) > .selector, 
form .form-group:not(.checkbox):not(.radio) > select, 
form .form-group:not(.checkbox):not(.radio) > textarea, 
form .form-group:not(.checkbox):not(.radio) > .ajax_blad {
  position: relative;
  top: -16px;
  left: 0px;
}
form .form-group.phone input {
  float: right;
  margin: 0px 0px 0px 80px !important;
  padding: 3px 30px 3px 8px !important;
  width: calc(100% - 105px);
}
form .form-group.phone_mobile input {
  float: right;
  margin: 0px 0px 0px 80px !important;
  padding: 3px 30px 3px 8px !important;
  width: calc(100% - 105px);
}
form .form-group .iti {
  height: 40px;
}
form .form-group .iti__flag-container {
  float: left;
  border: solid 1px #D6D4D4;
  margin: 0px 15px 0px 0px;
  width: 90px;
  height: 40px;
  background-color: #FFFFFF;
}
form .form-group .iti__flag-container .iti__selected-flag {
  background-color: #FFFFFF;
}
form .form-group .iti__flag-container .iti__selected-flag:hover {
  background-color: #FFFFFF;
}
form .form-group .iti__flag-container .iti__selected-flag.hover,
form .form-group .iti__flag-container .iti__selected-flag:focus,
form .form-group .form-control:not([disabled]):not([readonly]).hover, 
form .form-group .form-control:not([disabled]):not([readonly]):focus {
  border-color: #D6D4D4;
  outline: 1px solid #D6D4D4;
  box-shadow: none;
}
form .form-group.form-error input:not([readonly]),
form .form-group.form-error input:not([disabled]),
form .form-group.form-error textarea:not([readonly]), 
form .form-group.form-error textarea:not([disabled]) {
  border: 1px solid #F13340 !important;
  color: #F13340 !important;
  background-color: #FFFFFF !important;
  background: url('/img/icon/form-error.png') 98% 12px no-repeat #FFFFFF !important;
}
form .form-group.form-error select:not([readonly]), 
form .form-group.form-error select:not([disabled]) {
  border: 1px solid #F13340 !important;
  color: #F13340 !important;
  background-color: #FFFFFF !important;
}
form .form-group.form-error select option {
  color: #9C9B9B !important;
  background-color: #FFFFFF !important;
}
form .form-group.form-error .iti__flag-container {
  border: 1px solid #F13340 !important;
  color: #F13340 !important;
  background-color: #FFFFFF !important;
}
form .form-group.form-error .form-control {
  padding-right: 30px;
}
form .form-group.form-error .iti__flag-container .iti__selected-flag.hover,
form .form-group.form-error .iti__flag-container .iti__selected-flag:focus,
form .form-group.form-error .form-control:not([disabled]):not([readonly]).hover, 
form .form-group.form-error .form-control:not([disabled]):not([readonly]):focus {
  border-color: #F13340;
  outline: 1px solid #F13340;
}
form .form-group.form-error[data-form="-98"] .iti__flag-container, 
form .form-group.form-error[data-form="-1"] .iti__flag-container, 
form .form-group.form-error[data-form="0"] .iti__flag-container, 
form .form-group.form-error[data-form="2"] .iti__flag-container, 
form .form-group.form-error[data-form="3"] .iti__flag-container, 
form .form-group.form-error[data-form="4"] .iti__flag-container, 
form .form-group.form-error[data-form="5"] .iti__flag-container, 
form .form-group.form-error[data-form="6"] .iti__flag-container, 
form .form-group.form-error[data-form="7"] .iti__flag-container, 
form .form-group.form-error[data-form="8"] .iti__flag-container, 
form .form-group.form-error[data-form="9"] .iti__flag-container, 
form .form-group.form-error[data-form="10"] .iti__flag-container {
  border: 1px solid #46A74E !important;
  color: #212121 !important;
  background-color: #FFFFFF !important;
}
form .form-group.form-error[data-form="-98"] .iti__flag-container .iti__selected-flag.hover,
form .form-group.form-error[data-form="-98"] .iti__flag-container .iti__selected-flag:focus, 
form .form-group.form-error[data-form="-1"] .iti__flag-container .iti__selected-flag.hover,
form .form-group.form-error[data-form="-1"] .iti__flag-container .iti__selected-flag:focus, 
form .form-group.form-error[data-form="0"] .iti__flag-container .iti__selected-flag.hover,
form .form-group.form-error[data-form="0"] .iti__flag-container .iti__selected-flag:focus, 
form .form-group.form-error[data-form="2"] .iti__flag-container .iti__selected-flag.hover,
form .form-group.form-error[data-form="2"] .iti__flag-container .iti__selected-flag:focus, 
form .form-group.form-error[data-form="3"] .iti__flag-container .iti__selected-flag.hover,
form .form-group.form-error[data-form="3"] .iti__flag-container .iti__selected-flag:focus, 
form .form-group.form-error[data-form="4"] .iti__flag-container .iti__selected-flag.hover,
form .form-group.form-error[data-form="4"] .iti__flag-container .iti__selected-flag:focus, 
form .form-group.form-error[data-form="5"] .iti__flag-container .iti__selected-flag.hover,
form .form-group.form-error[data-form="5"] .iti__flag-container .iti__selected-flag:focus, 
form .form-group.form-error[data-form="6"] .iti__flag-container .iti__selected-flag.hover,
form .form-group.form-error[data-form="6"] .iti__flag-container .iti__selected-flag:focus, 
form .form-group.form-error[data-form="7"] .iti__flag-container .iti__selected-flag.hover,
form .form-group.form-error[data-form="7"] .iti__flag-container .iti__selected-flag:focus, 
form .form-group.form-error[data-form="8"] .iti__flag-container .iti__selected-flag.hover,
form .form-group.form-error[data-form="8"] .iti__flag-container .iti__selected-flag:focus, 
form .form-group.form-error[data-form="9"] .iti__flag-container .iti__selected-flag.hover,
form .form-group.form-error[data-form="9"] .iti__flag-container .iti__selected-flag:focus,
form .form-group.form-error[data-form="10"] .iti__flag-container .iti__selected-flag.hover,
form .form-group.form-error[data-form="10"] .iti__flag-container .iti__selected-flag:focus {
  border-color: #46A74E;
  outline: 1px solid #46A74E;
}
form .form-group.form-ok input:not([readonly]),
form .form-group.form-ok input:not([disabled]),
form .form-group.form-ok textarea:not([readonly]), 
form .form-group.form-ok textarea:not([disabled]) {
  border: 1px solid #46A74E !important;
  color: #212121 !important;
  background-color: #FFFFFF !important;
  background: url('/img/icon/form-ok.png') 98% 12px no-repeat #FFFFFF !important;
}
form .form-group.form-ok select:not([readonly]), 
form .form-group.form-ok select:not([disabled]) {
  border: 1px solid #46A74E !important;
  color: #212121 !important;
  background-color: #FFFFFF !important;
}
form .form-group.form-ok select option {
  color: #9C9B9B !important;
  background-color: #FFFFFF !important;
}
form .form-group.form-ok .iti__flag-container {
  border: 1px solid #46A74E !important;
  color: #212121 !important;
  background-color: #FFFFFF !important;
}
form .form-group.form-ok .form-control {
  padding-right: 30px;
}
form .form-group.form-ok .iti__flag-container .iti__selected-flag.hover,
form .form-group.form-ok .iti__flag-container .iti__selected-flag:focus,
form .form-group.form-ok .form-control:not([disabled]):not([readonly]).hover, 
form .form-group.form-ok .form-control:not([disabled]):not([readonly]):focus {
  border-color: #46A74E;
  outline: 1px solid #46A74E;
}
.vat_number_list {
  background: #fff;
  border: 1px solid #d6d4d4;
  width: 271px;
  margin-top: -1px;
}
.vat_number_list li {
  padding: 0 10px;
  font-weight: normal;
  color: #212121;
  font-size: 13px;
  line-height: 22px;
}
.vat_number_list li.vnl_odd {
  background: #fff;
}
.vat_number_list li:hover {
  background: #fbfbfb;
}
.vat_number_list li.vnl_over {
  background: #fbfbfb;
}
.vat_number_list {
  text-align: left;
  padding: 0;
  background-color: white;
  overflow: hidden;
  z-index: 38;
}
.vat_number_list ul {
  width: 100%;
  list-style-position: outside;
  list-style: none;
  padding: 0;
  margin: 0;
}
.vat_number_list li {
  margin: 0;
  padding: 2px 5px;
  cursor: default;
  display: block;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
}
.vat_number_list .vnl_odd {
  background-color: #eee;
}
.vat_number_list .vnl_over {
  background-color: #0a246a;
  color: white;
}