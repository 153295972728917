ul.box > li::marker {
  content: '';
}
ul.address {
  color: #212121;
  outline: solid 1px #FFFFFF;
  border: solid 1px #D6D4D4;
  margin: 0px 0px 0px 0px;
  padding: 0px 15px 0px 15px;
  line-height: 23px;
}
li > ul.address {
  cursor: pointer;
}
li > ul.address:hover {
  outline: solid 1px #D6D4D4;
  border: solid 1px #D6D4D4;
}
li.active > ul.address {
  outline: solid 1px #F4A700;
  border: solid 1px #F4A700;
}
ul.address > li {
  min-height: 24px;
  list-style: none;
}
ul.address > li > span {
  padding: 5px 0px 5px 0px;
  height: 24px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
}
ul.address > li > output {
  padding: 3px 0px 3px 0px;
  height: 24px;
  font-size: 13px;
}
ul.address .address_title {
  position: relative;
  top: -7px;
  left: -7px;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  height: 16px;
  white-space: nowrap;
  line-height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #212121;
  background-color: #FFFFFF;
}
ul.address .address_title .page-subheading {
  color: #212121;
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px 5px 0px 5px;
  height: 20px;
  white-space: nowrap;
  line-height: 16px;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
}
ul.address hr {
  margin: 5px 0px 5px 0px;
  padding: 0px 0px 0px 0px;
}
ul.address .address_update {
  display: block;
  margin: 0px 0px 5px 0px;
  height: 40px;
  width: 100%;
}
ul.address .address_update a {
  display: inline-block !important;
  float: right;
  margin: 0px 0px 0px 15px;
  padding: 0px 0px 0px 0px;
  height: 40px;
  font-size: 14px;
  color: #F4A700;
  text-transform: uppercase;
  cursor: pointer;
}
ul.address .address_update a span {
  display: block !important;
  margin: 0px 0px 0px 0px;
  padding: 13px 0px 13px 15px;
  height: 40px;
}
ul.address .address_update a:hover {
  opacity: 0.7;
}